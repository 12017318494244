import { BlockTypeHandle } from './types';

export const blockImports = {
  [BlockTypeHandle.PRODUUCT_RECOMMENDATIONS]: defineAsyncComponent(
    () =>
      import(
        '~~/src/components/block/productRecommedations/productRecommendations.vue'
      ),
  ),
  [BlockTypeHandle.CAMPAIGN_LIST]: defineAsyncComponent(
    () => import('~~/src/components/block/campaignList/campaignList.vue'),
  ),
  [BlockTypeHandle.MANUFACTURER_OVERVIEW]: defineAsyncComponent(
    () =>
      import(
        '~~/src/components/block/manufacturerOverview/manufacturerOverview.vue'
      ),
  ),
  [BlockTypeHandle.STARTPAGE_TEMPLATE]: defineAsyncComponent(
    () =>
      import('~~/src/components/block/startPageTemplate/startPageTemplate.vue'),
  ),
  [BlockTypeHandle.BANNER_POSITION]: defineAsyncComponent(
    () =>
      import('~~/src/components/block/bannerPosition/bannerPositionBlock.vue'),
  ),
  [BlockTypeHandle.MANUFACTURER_PRODUCT_LIST]: defineAsyncComponent(
    () =>
      import(
        '~~/src/components/block/manufacturerProductList/manufacturerProductList.vue'
      ),
  ),
  [BlockTypeHandle.PRODUCT_TAG_PRODUCT_LIST]: defineAsyncComponent(
    () =>
      import(
        '~~/src/components/block/productTagProductList/productTagProductList.vue'
      ),
  ),
  [BlockTypeHandle.PRODUCT_NUMBER_PRODUCT_LIST]: defineAsyncComponent(
    () =>
      import(
        '~~/src/components/block/productNumberProductList/productNumberProductList.vue'
      ),
  ),
};
