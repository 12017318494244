import { BlockTypeHandle } from './types';

export const blockImports = {
  [BlockTypeHandle.EVENT_DETAILED]: defineAsyncComponent(
    () => import('~/components/block/eventDetailed/event-detailed.vue'),
  ),
  [BlockTypeHandle.CTA_OFFER]: defineAsyncComponent(
    () =>
      import('~~/src/components/block/cta/templateCTA/OfferTemplateCTA.vue'),
  ),
  [BlockTypeHandle.CTA_EVENT]: defineAsyncComponent(
    () =>
      import('~~/src/components/block/cta/templateCTA/EventTemplateCTA.vue'),
  ),
  [BlockTypeHandle.CTA_SERVICE]: defineAsyncComponent(
    () =>
      import('~~/src/components/block/cta/templateCTA/ServiceTemplateCTA.vue'),
  ),
  [BlockTypeHandle.CTA_OFFICE]: defineAsyncComponent(
    () =>
      import('~~/src/components/block/cta/templateCTA/OfficeTemplateCTA.vue'),
  ),
  [BlockTypeHandle.CTA_CONTACT]: defineAsyncComponent(
    () => import('~~/src/components/block/cta/vdv/CallToActionContact.vue'),
  ),
  [BlockTypeHandle.HEADLINE]: defineAsyncComponent(
    () => import('~/components/block/headline/vdv/headline.vue'),
  ),
  [BlockTypeHandle.TEXT_IMAGE]: defineAsyncComponent(
    () => import('~/components/block/textImage/vdv/text-image.vue'),
  ),
  [BlockTypeHandle.POST_LIST]: defineAsyncComponent(
    () => import('~/components/block/list/postList/vdv/list.vue'),
  ),
  [BlockTypeHandle.EVENT_LIST]: defineAsyncComponent(
    () => import('~/components/block/list/eventList/vdv/list.vue'),
  ),
  [BlockTypeHandle.OFFICE_LIST]: defineAsyncComponent(
    () => import('~/components/block/list/officeList/vdv/list.vue'),
  ),
  [BlockTypeHandle.OFFER_LIST]: defineAsyncComponent(
    () => import('~/components/block/list/offerList/vdv/list.vue'),
  ),
  [BlockTypeHandle.AUTOBLOCK]: defineAsyncComponent(
    () => import('~/components/block/autoblock/vdv/autoblock.vue'),
  ),
  [BlockTypeHandle.AREA_INTRO_TOP_BANNER]: defineAsyncComponent(
    () =>
      import(
        '~/components/block/areaIntroTopBanner/vdv/area-intro-top-banner.vue'
      ),
  ),
  [BlockTypeHandle.FILE_DOWNLOADS]: defineAsyncComponent(
    () => import('~/components/block/fileDownloads/file-downloads.vue'),
  ),
  [BlockTypeHandle.CONTENT_ENUMERATION]: defineAsyncComponent(
    () =>
      import(
        '~/components/block/contentEnumeration/vdv/content-enumeration.vue'
      ),
  ),
  [BlockTypeHandle.EVENT_ENUMERATION]: defineAsyncComponent(
    () => import('~/components/block/eventEnumeration/event-enumeration.vue'),
  ),
  [BlockTypeHandle.CONTENT_HEADER]: defineAsyncComponent(
    () => import('~/components/block/contentHeader/content-header.vue'),
  ),
  [BlockTypeHandle.CTA]: defineAsyncComponent(
    () => import('~~/src/components/block/cta/vdv/CallToAction.vue'),
  ),
  [BlockTypeHandle.CIRCLE_TEXT]: defineAsyncComponent(
    () => import('~/components/block/circleText/vdv/circle-text.vue'),
  ),
  [BlockTypeHandle.CONTENT_PROCESS]: defineAsyncComponent(
    () => import('~/components/block/contentProcess/vdv/content-process.vue'),
  ),
  [BlockTypeHandle.TOP_SPEAKERS]: defineAsyncComponent(
    () => import('~/components/block/topSpeakers/vdv/top-speakers.vue'),
  ),
  [BlockTypeHandle.TEAM]: defineAsyncComponent(
    () => import('~/components/block/team/vdv/team.vue'),
  ),
  [BlockTypeHandle.ACCORDION_FAQS]: defineAsyncComponent(
    () => import('~/components/block/accordionFAQs/vdv/accordion-faqs.vue'),
  ),
  [BlockTypeHandle.HIGHLIGHT]: defineAsyncComponent(
    () => import('~/components/block/highlight/vdv/highlight.vue'),
  ),
  [BlockTypeHandle.FLOWCHART]: defineAsyncComponent(
    () => import('~/components/block/flowchart/vdv/flowchart.vue'),
  ),
  [BlockTypeHandle.CONTACT_REQUEST]: defineAsyncComponent(
    () => import('~/components/block/contactRequest/vdv/contact-request.vue'),
  ),
  [BlockTypeHandle.COMPARISON_TABLE]: defineAsyncComponent(
    () => import('~/components/block/comparisonTable/vdv/comparison-table.vue'),
  ),
  [BlockTypeHandle.PERSON_RATING]: defineAsyncComponent(
    () => import('~/components/block/personRating/vdv/person-rating.vue'),
  ),
  [BlockTypeHandle.TEXT_IMAGE_TILES]: defineAsyncComponent(
    () => import('~/components/block/textImageTiles/vdv/text-image-tiles.vue'),
  ),
  [BlockTypeHandle.BRANDS]: defineAsyncComponent(
    () => import('~/components/block/brands/vdv/brands.vue'),
  ),
  [BlockTypeHandle.AGREEMENT_TILES]: defineAsyncComponent(
    () => import('~/components/block/agreementTiles/agreement-tiles.vue'),
  ),
  [BlockTypeHandle.OFFER_TILES]: defineAsyncComponent(
    () => import('~/components/block/offerTiles/offer-tiles.vue'),
  ),
  [BlockTypeHandle.GRID_TABLE]: defineAsyncComponent(
    () => import('~/components/block/gridTable/vdv/grid-table.vue'),
  ),
  [BlockTypeHandle.ROOMPLANNING_REFERENCES]: defineAsyncComponent(
    () =>
      import(
        '~/components/block/roomplanningReferences/roomplanning-references.vue'
      ),
  ),
  [BlockTypeHandle.CONTENT_TEASER]: defineAsyncComponent(
    () => import('~/components/block/contentTeaser/content-teaser.vue'),
  ),
  [BlockTypeHandle.FAQ_ADVANCED]: defineAsyncComponent(
    () => import('~/components/block/faqAdvanced/vdv/faq-advanced.vue'),
  ),
  [BlockTypeHandle.PERSONIO_JOBS]: defineAsyncComponent(
    () => import('~~/src/components/block/personio-jobs/vdv/personio-jobs.vue'),
  ),
  [BlockTypeHandle.MAILCHIMP_PAYWALL_FORM]: defineAsyncComponent(
    () =>
      import(
        '~/components/block/mailchimpPaywallForm/mailchimp-paywall-form.vue'
      ),
  ),
  [BlockTypeHandle.OFFICE_TABLE]: defineAsyncComponent(
    () => import('~/components/block/officeTable/office-table.vue'),
  ),
  [BlockTypeHandle.SEPERATOR]: defineAsyncComponent(
    () => import('~/components/block/seperator/vdv/contentSeparator.vue'),
  ),
  [BlockTypeHandle.NEWSLETTER_BANNER]: defineAsyncComponent(
    () =>
      import('~/components/block/newsletterBanner/vdv/newsletter-banner.vue'),
  ),
  [BlockTypeHandle.CONTENT_IMAGE]: defineAsyncComponent(
    () => import('~/components/block/contentImage/content-image.vue'),
  ),
  [BlockTypeHandle.VIDEO]: defineAsyncComponent(
    () => import('~/components/block/video/video.vue'),
  ),
  [BlockTypeHandle.GLOSSARY]: defineAsyncComponent(
    () => import('~/components/components/cms/glossary/vdv/glossary.vue'),
  ),
  [BlockTypeHandle.PRINT_HEADER]: defineAsyncComponent(
    () => import('~/components/block/printHeader/vdv/header.vue'),
  ),
  [BlockTypeHandle.PRINT_FOOTER]: defineAsyncComponent(
    () => import('~/components/block/printFooter/vdv/footer.vue'),
  ),
};
