import { blockImports as vdvBlocks } from '~~/src/components/block/dynamicVdvBlockImports';
import { blockImports } from '~/components/block/dynamicBlockImports';
import { cmsImports } from '~~/src/components/components/cms/dynamicVdvCmsImports';
import { BlockTypeHandle } from '../../block/types';

export const pageBlocksImports: Partial<Record<BlockTypeHandle, any>> = {
  ...vdvBlocks,
  ...cmsImports,
  [BlockTypeHandle.EVENT_CME_POINTS]: defineAsyncComponent(
    () => import('~/components/block/eventCmePoints//cme-points.vue'),
  ),
  ...blockImports,
};

export const defaultBlockImport = defineAsyncComponent(
  () => import('~/components/components/cms/blockType.vue'),
);
