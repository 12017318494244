import { BlockTypeHandle } from '../../block/types';

export const cmsImports = {
  [BlockTypeHandle.STYLED_SECTION]: defineAsyncComponent(
    () =>
      import(
        '~/components/components/cms/styledSection/vdv/styled-section.vue'
      ),
  ),
  [BlockTypeHandle.FORM]: defineAsyncComponent(
    () => import('~/components/components/cms/form/form.vue'),
  ),
  [BlockTypeHandle.FORM_REPAIRSHOP_REQUEST]: defineAsyncComponent(
    () => import('~/components/form/repairshop/repairshop-request.vue'),
  ),
  [BlockTypeHandle.FORM_DISTURBANCE_REQUEST]: defineAsyncComponent(
    () => import('~/components/form/disturbance/form.vue'),
  ),
  [BlockTypeHandle.EVENT_INTRODUCTION]: defineAsyncComponent(
    () =>
      import(
        '~/components/components/cms/eventIntroduction/event-introduction.vue'
      ),
  ),
  [BlockTypeHandle.POST_INTRODUCTION]: defineAsyncComponent(
    () =>
      import(
        '~/components/components/cms/postIntroduction/vdv/post-introduction.vue'
      ),
  ),
  [BlockTypeHandle.SERVICE_INTRODUCTION]: defineAsyncComponent(
    () =>
      import(
        '~/components/components/cms/serviceIntroduction/service-introduction.vue'
      ),
  ),
  [BlockTypeHandle.PERSON]: defineAsyncComponent(
    () => import('~/components/components/cms/person/vdv/person.vue'),
  ),
  [BlockTypeHandle.INTRODUCTION]: defineAsyncComponent(
    () =>
      import('~/components/components/cms/introduction/vdv/introduction.vue'),
  ),
  [BlockTypeHandle.TEXT]: defineAsyncComponent(
    () => import('~/components/components/cms/text/text.vue'),
  ),
  [BlockTypeHandle.TOP_BANNER]: defineAsyncComponent(
    () => import('~/components/components/cms/topBanner/top-banner.vue'),
  ),
  [BlockTypeHandle.WYSIWYG_CONTENT]: defineAsyncComponent(
    () =>
      import('~/components/components/cms/wysiwygBlock/vdv/wysiwygBlock.vue'),
  ),
  [BlockTypeHandle.IMAGE_GALLERY]: defineAsyncComponent(
    () =>
      import('~/components/components/cms/imageGallery/vdv/image-gallery.vue'),
  ),
  [BlockTypeHandle.IMAGE_GALLERY_EXTENDED]: defineAsyncComponent(
    () =>
      import(
        '~/components/components/cms/imageGalleryExtended/vdv/image-gallery-extended.vue'
      ),
  ),
  [BlockTypeHandle.QUOTE]: defineAsyncComponent(
    () => import('~/components/components/cms/quote/vdv/quote.vue'),
  ),
};
